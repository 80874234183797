import React from "react"
import PropTypes from "prop-types"
import {
  ExampleGridItem,
  ExampleHoverBackground,
  ExampleArrow,
  ExampleScreenshotWrapper,
} from "@components/resources/example-grid"

import Heading from "@components/elements/heading"
import Anchor from "@components/elements/anchor"
import Grid from "@components/elements/grid"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Image from "@components/image"
import styled from "styled-components"
import Paragraph from "@components/elements/paragraph"

export const Screenshot = styled.div`
  position: relative;
  object-fit: cover;
  overflow: hidden;

  svg {
    max-height: 100%;
    margin: 0 auto;
  }

  justify-content: center;

  position: relative;
  display: flex;
  align-items: center;
  object-fit: cover;
  height: 20rem;
  padding: 2rem;
  .klipfolio-image-wrapper div {
    box-shadow: 0 15px 20px rgb(32 51 70 / 30%), 0 0px 30px rgb(32 51 70 / 20%);
    border-radius: 0.75rem;
    img {
      max-height: 16rem;
      width: auto;
      object-fit: contain;
    }
  }
`

export const RelatedResourceGrid = ({ title, examples, kpi }) => {
  const type = kpi ? "KPI" : "Dashboard"
  const typeSlug = kpi ? "kpi-examples" : "dashboard-examples"

  return (
    <Flex gap="2rem" padding="3rem" borderRadius="1rem" background="#FAFAFF">
      <Heading as={"h2"}>{title ?? `Related ${type} Examples`}</Heading>
      <Grid
        as="ul"
        gap="2rem"
        gapMd="1rem"
        margin="0"
        columns="repeat(2,1fr)"
        columnsSm="1fr"
      >
        {examples.map((example, i) => {
          const name = example.name ?? example.title
          const slug = `/resources/${typeSlug}/${example.category.slug}/${example.slug}`

          return (
            example.status === "published" && (
              <Div as="li" margin="0" key={`${type}-${example.id}-${i}`}>
                <ExampleGridItem>
                  <ExampleScreenshotWrapper to={slug}>
                    <Screenshot>
                      {example.image && (
                        <Image
                          file={example.image}
                          alt={`${example.category.name} - ${example.name}`}
                          radius={0.75}
                          showOverflow
                        />
                      )}
                      <ExampleHoverBackground radius={"0.5rem 0.5rem 0 0"}>
                        <ExampleArrow />
                      </ExampleHoverBackground>
                    </Screenshot>
                  </ExampleScreenshotWrapper>
                  <Flex height="100%" padding="1.5rem">
                    {(example.related_description || example.summary) && (
                      <Paragraph
                        lineHeight={1.7}
                        fontSize="1.1rem"
                        margin="0 0 1rem"
                      >
                        {example.related_description ?? example.summary}
                      </Paragraph>
                    )}
                    <Anchor style={{ marginTop: "auto" }} arrow to={slug}>
                      {name}
                    </Anchor>
                  </Flex>
                </ExampleGridItem>
              </Div>
            )
          )
        })}
      </Grid>
    </Flex>
  )
}

RelatedResourceGrid.propTypes = {
  title: PropTypes.string,
  examples: PropTypes.arrayOf(PropTypes.object).isRequired,
  kpi: PropTypes.bool,
}
