import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Html from "@components/html"
import ResourceHeader from "@components/resources/header"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import { ResourceGrid } from "@components/resources/resource-grid"
import theme from "@styles/theme"
import { ResourceWrapper } from "@components/resources/resource-wrapper"
import { RelatedResourceGrid } from "@components/dashboard-examples/related-resource-grid"
import { cleanDashboardName } from "@utils/name-cleaner"
import { NeutralCTA } from "@components/cta/eop/neutral"

const DashboardExample = ({ data, pageContext }) => {
  const dashboard = data.dashboard
  const image = dashboard.image
  const imageUrl = image?.cdn
  const ctaName = cleanDashboardName(dashboard.name)
  const relatedExamples =
    dashboard.related_examples?.length > 0
      ? dashboard.related_examples
      : null

  return (
    <Layout
      image={imageUrl}
      fullWidth={true}
      canonical={pageContext.slug}
      title={dashboard.title}
      description={dashboard.summary}
      seo={dashboard.seo}
      body={dashboard.body}
      date_updated={dashboard.date_updated}
    >
      <article>
        <ResourceWrapper
          gap="6rem"
          margin="0 0 6rem"
          marginMd="0 0 4rem"
          marginSm="0 0 2rem"
        >
          <ResourceHeader
            name={dashboard.name}
            alt={`${dashboard.category.name} Example - ${dashboard.name}`}
            subtitle={dashboard.subtitle}
            image={image}
            newCta
            ctaCategory={ctaName}
            links={[
              { text: "Resources", url: "/resources" },
              {
                text: "Dashboard Examples",
                url: "/resources/dashboard-examples",
              },
              {
                text: dashboard.category.name,
                url: `/resources/dashboard-examples/${dashboard.category.slug}`,
              },
              {
                text: dashboard.name,
              },
            ]}
          />
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              {!dashboard.bypass_h2 && (
                <Heading as="h2">What is a {dashboard.name}?</Heading>
              )}
              <Html
                parseElements
                inherit
                html={dashboard.body}
                images={dashboard.body_images}
                cta
              />
            </Flex>
          </Container>
          {relatedExamples && (
            <Container>
              <RelatedResourceGrid
                title={dashboard.related_title}
                examples={relatedExamples}
              />
            </Container>
          )}
          {dashboard.body2 && (
            <Container text>
              <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
                <Html
                  inherit
                  parseElements
                  html={dashboard.body2}
                  images={dashboard.body2_images}
                  cta
                />
              </Flex>
            </Container>
          )}
          {dashboard.body3 && (
            <Container text>
              <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
                <Html
                  inherit
                  parseElements
                  html={dashboard.body3}
                  images={dashboard.body3_images}
                  cta
                />
              </Flex>
            </Container>
          )}
          {dashboard.show_related && !relatedExamples && (
            <Container>
              <ResourceGrid
                title={"Related"}
                examples={pageContext.relatedExamples}
                related
              />
            </Container>
          )}
        </ResourceWrapper>
        <NeutralCTA />
      </article>
    </Layout>
  )
}

DashboardExample.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default DashboardExample

export const pageQuery = graphql`
  query DashboardExamplePageQuery($id: String!) {
    dashboard: dashboardExamples(id: { eq: $id }) {
      name
      bypass_h2
      title
      subtitle
      category {
        name
        slug
      }
      status
      id
      body
      body2
      body3
      body_images {
        cdn
        placeholder
        directus_id
        id
        title
      }
      body2_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      body3_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      summary
      date_updated
      image {
        id
        title
        cdn
        placeholder
      }
      seo {
        ...seoData
      }
      show_related
      related_title
      related_examples {
        slug
        name
        id
        summary
        status
        related_description
        image {
          cdn
          placeholder
          id
          title
        }
        category {
          name
          slug
        }
      }
    }
  }
`
